/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import App from './app';

/**
 * Main function.
 */
function main() {
  const app = new App();
  app.initialize();

  // console.log('App initialized...', app);
}

// Ensures DOM is fully loaded before running app's main logic.
// Loading hasn't finished yet...
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', main);
} else {
  // `DOMContentLoaded` has already been fired...
  main();
}
