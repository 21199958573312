// import modules that define the main functionalities of the app
import Autocompleter from './modules/autocompleter';
import Clicks from './modules/clicks';
import Ratings from './modules/ratings';

export default class App {
  /**
   * Autocompleter module instance
   *
   * @type {Autocompleter}
   */
  autocompleter;

  /**
   * Clicks module instance
   *
   * @type {Clicks}
   */
  clicks;

  /**
   * Ratings module instance
   *
   * @type {Ratings}
   */
  ratings;

  constructor(options = {}) {
    let rootpath = window.location.pathname;
    let isCourtier = rootpath.indexOf("/courtier") > -1;

    this.options = Object.assign(
      {},
      {
        // define current environment (defined in environment variable as: development, staging, prod)
        environment: inbenta.seo.env.INBENTA_ENV,
        trackingpath: (isCourtier ? '/courtier' : '') + '/tracking',
        autocompletepath: (isCourtier ? '/courtier' : '') + '/autocomplete',
      },
      options,
    );
  }

  initialize() {
    this.autocompleter = new Autocompleter(this.options);
    this.clicks = new Clicks(this.options);
    this.ratings = new Ratings(this.options);

    const childContent = document.querySelector('.inbenta-helpsite.categories-child .container__category-contents');
    const popularContent = document.querySelector('.inbenta-helpsite.categories-child .inbenta-km__popular');
    if (childContent && popularContent && childContent.clientHeight > 0 && popularContent.clientHeight > 0) {
      document.querySelector('footer.site-footer').style.marginTop = Math.max(childContent.clientHeight, popularContent.clientHeight) + 'px';
    }

    var toggler = document.querySelector('#burger_toggler');
    var burger = document.querySelector('.container__burger__content');

    toggler.addEventListener("click", togglerClick, false);

    function togglerClick(event) {
      event.preventDefault();
      toggler.classList.toggle('active');
      burger.classList.toggle('active');
    }
  }
}
